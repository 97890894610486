import React, { useEffect, useState } from 'react';
import { formatDateTime } from '../components/Util.js';
import TestExecService from '../service/TestExecService.js';
import { McCCodePreview } from '@maersk-global/community-ui-react-wrapper';
import { McIcon } from '@maersk-global/mds-react-wrapper';
import { formatUnixTimestamp } from '../components/Util';
const Debug = ({ testData }) => {
  const [timelineData, setTimelineData] = useState({
    testExecution: null,
    k6TestStats: null,
    testExecutionTimelineDate: null
  });
  const [error, setError] = useState(null);
  const testExecService = new TestExecService();
  useEffect(() => {
    const fetchTestStats = async () => {
      try {
        const response = await testExecService.getTimelineByTestId(testData.testId);
        setTimelineData({
          testExecution: formattedTestData(response.data.testExecution),
          k6TestStats: formatTestStats(response.data.k6TestStats),
          testExecutionTimelineDate: response.data.timelineEntries
        });
      } catch (error) {
        setError(error.message);
      }
    };
    fetchTestStats();
  }, []);

  const formattedTestData = (testExecution) => {
    if (!testExecution) return {};
    return {
      ...testExecution,
      createDateTime: testExecution.createDateTime
        ? formatDateTime(testExecution.createDateTime)
        : '',
      startDateTime: testExecution.startDateTime ? formatDateTime(testExecution.startDateTime) : '',
      stopDateTime: testExecution.stopDateTime ? formatDateTime(testExecution.stopDateTime) : '',
      updatedDateTime: testExecution.updatedDateTime
        ? formatDateTime(testExecution.updatedDateTime)
        : '',
      testTemplate: testExecution.testTemplate
        ? {
            ...testExecution.testTemplate,
            createDateTime: testExecution.testTemplate.createDateTime
              ? formatDateTime(testExecution.testTemplate.createDateTime)
              : ''
          }
        : {},
      varsJson: testExecution.varsJson ? JSON.parse(testExecution.varsJson) : {},
      optionsJson: testExecution.optionsJson ? JSON.parse(testExecution.optionsJson) : {},
      k6DependencyJson: testExecution.k6DependencyJson
        ? JSON.parse(testExecution.k6DependencyJson)
        : {}
    };
  };
  const formatTestStats = (stats) => {
    if (!stats) return [];
    return stats.map((stat) => ({
      ...stat,
      createDateTime: stat.createDateTime ? formatDateTime(stat.createDateTime) : null,
      updatedDateTime: stat.updatedDateTime ? formatDateTime(stat.updatedDateTime) : null,
      podList: Array.isArray(stat.podList)
        ? stat.podList.map((pod) => ({
            ...pod,
            podStartTime: pod.podStartTime ? formatDateTime(pod.podStartTime) : null,
            container: pod.container
              ? {
                  ...pod.container,
                  startedAt: pod.container.startedAt
                    ? formatDateTime(pod.container.startedAt)
                    : null,
                  finishedAt: pod.container.finishedAt
                    ? formatDateTime(pod.container.finishedAt)
                    : null
                }
              : null
          }))
        : []
    }));
  };

  const fromTime = testData.startDateTime ? formatUnixTimestamp(testData.startDateTime) : 'now-2d';
  const toTime = testData.stopDateTime ? formatUnixTimestamp(testData.stopDateTime) : 'now';

  const grafanaUrl = `https://grafana.maersk.io/d/ce43710b-0d5e-41a1-8b87-42b8741e7719/ipace-kubernetes-clusters-events?from=${fromTime}&to=${toTime}&var-filter=${testData.testId}`;

  return (
    <div>
      <ol>
        {timelineData.testExecutionTimelineDate &&
          timelineData.testExecutionTimelineDate.map((item, index) => (
            <li key={index}>
              {item.formattedDateTime} - {item.label}
            </li>
          ))}
      </ol>
      <br></br>
      <div className="mds-text--x-small-normal">
        <McCCodePreview
          heading="Test Execution Data"
          fit="small"
          variant="none"
          appearance="inverse"
          code={[
            {
              language: 'json',
              template: JSON.stringify(timelineData.testExecution, null, 2)
            }
          ]}
        />
      </div>
      <br></br>
      <div className="mds-text--x-small-normal">
        <McCCodePreview
          fit="small"
          variant="none"
          appearance="inverse"
          code={[
            {
              language: 'json',
              template: JSON.stringify(timelineData.k6TestStats, null, 2)
            }
          ]}>
          <div slot="heading">
            <span>Test Stats Data</span>&nbsp;
            <a href={grafanaUrl} target="_blank" rel="noopener noreferrer">
              Pod Events Dashboard
              <McIcon icon="square-arrow-up-right" />
            </a>
          </div>
        </McCCodePreview>
      </div>
    </div>
  );
};

export default Debug;
