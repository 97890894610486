import axios from 'axios';
import { getCoreServiceUrl } from '../components/Util.js';

class TestTmplService {
  coreServiceUrl = `${getCoreServiceUrl()}/core-srv`;
  baseUrl = `${this.coreServiceUrl}/test-tmpl`;

  // async getAllTestTemplate(params) {
  //   let url = `${this.baseUrl}/list`;
  //   return await axios
  //     .get(url, {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       data: {},
  //       params: params
  //     })
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     });
  // }
  async postTestTemplate(data) {
    let url = `${this.baseUrl}`;
    return await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  async updateTestTemplate(templateId, data) {
    let url = `${this.baseUrl}/${templateId}`;
    return await axios
      .put(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  async getTestTemplateById(templateId) {
    let url = `${this.baseUrl}/${templateId}`;
    return await axios
      .get(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {},
        params: {
          pageSize: 25
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  async deleteTestTemplate(templateId) {
    let url = `${this.baseUrl}/${templateId}`;
    return axios
      .delete(url, {
        validateStatus: function (status) {
          return status === 200 || status === 409;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  async getByFilters(params) {
    let url = `${this.baseUrl}/filter`;
    return await axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params,
        validateStatus: function (status) {
          return status === 200;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}
export default TestTmplService;
