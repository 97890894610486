import axios from 'axios';
import { getMACUrl } from '../components/Util';
import OnboardingConfigService from '../service/OnboardingConfigService';
import UserProfileService from '../service/UserProfileService';

class ApplicationOnboardingService {
  userProfileService = new UserProfileService();
  onboardingConfigService = new OnboardingConfigService();

  addOnboardingConfigId(newOnboardingConfigId) {
    return this.userProfileService.addOnboardingConfigId(newOnboardingConfigId);
  }

  getAllOnboardingConfigs() {
    return this.onboardingConfigService.getAllOnboardingConfigs();
  }

  createOnboardingConfig(onboardingConfigRequest) {
    return this.onboardingConfigService.createOnboardingConfig(onboardingConfigRequest);
  }
  switchOnboardingConfigId(onboardingConfigId) {
    return this.userProfileService.switchOnboardingConfig(onboardingConfigId);
  }

  macUrl = getMACUrl();

  getMACApplications(searchQuery) {
    let url = `${this.macUrl}/search`;
    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json'
        },
        params: {
          page: 0,
          size: 25,
          sort: 'applicationName,asc',
          app: searchQuery
        }
      })
      .then((response) => {
        return response;
      });
  }

  getMACPlatforms() {
    let url = `${this.macUrl}/platforms`;
    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json'
        },
        params: {
          page: 0,
          size: 200
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default ApplicationOnboardingService;
