import { McTooltip } from '@maersk-global/mds-react-wrapper';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { formatUnixTimestamp } from '../components/Util';

const isEnabled = (status) => {
  return (
    status === 'START_TEST' ||
    status === 'STOP_REQUESTED' ||
    status === 'FAILED_TO_COMPLETE' ||
    status === 'STOPPED' ||
    status === 'COMPLETED'
  );
};

const getUrl = (testData, isLogsUrl) => {
  const envUrl = isLogsUrl
    ? process.env.REACT_APP_K6_LOGS_URL
    : process.env.REACT_APP_k6_METRICS_URL;
  let url = `${envUrl}&var-test_id=${testData.testId}`;
  // Only add timestamp parameters if they exist
  if (testData.startDateTime) {
    url += `&from=${formatUnixTimestamp(testData.startDateTime)}`;
  }
  if (testData.stopDateTime) {
    url += `&to=${formatUnixTimestamp(testData.stopDateTime)}`;
  }
  return url;
};

const MetricsButton = ({ testData }) => {
  let metricUrl = getUrl(testData, false);

  return (
    <McTooltip>
      <McButton
        slot="trigger"
        disabled={!isEnabled(testData.status)}
        //variant="outlined"
        trailingicon="square-arrow-up-right"
        href={metricUrl}
        target="_blank">
        Metrics
      </McButton>
      {!isEnabled(testData.status) ? (
        <span>Metrics will be available once test is started.</span>
      ) : (
        ''
      )}
    </McTooltip>
  );
};

const LogsButton = ({ testData }) => {
  let logUrl = getUrl(testData, true);

  return (
    <McTooltip>
      <McButton
        slot="trigger"
        disabled={!isEnabled(testData.status)}
        //variant="outlined"
        trailingicon="square-arrow-up-right"
        href={logUrl}
        target="_blank">
        Logs
      </McButton>
      {!isEnabled(testData.status) ? <span>Logs will be available once test is started.</span> : ''}
    </McTooltip>
  );
};

export { LogsButton, MetricsButton };
