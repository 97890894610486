import { McButton } from '@maersk-global/mds-react-wrapper';
import { McRadio } from '@maersk-global/mds-react-wrapper/components-core/mc-radio';
import { McSwitch } from '@maersk-global/mds-react-wrapper/components-core/mc-switch';
import { McTable } from '@maersk-global/mds-react-wrapper/components-core/mc-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowErrorNotification, ShowSuccessNotification } from '../common/notification.jsx';
import Loading from '../components/pages/Loading.jsx';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import UserProfileService from '../service/UserProfileService.js';
import ApplicationOnboardingService from '../Onboarding/ApplicationOnboardingService';
const getPortfolios = require('../components/portfolioUtils.jsx');
const { portfolioMap: portfolios } = getPortfolios();
const Applications = () => {
  let userProfileService = new UserProfileService();
  let applicationOnboardingService = new ApplicationOnboardingService();

  const navigate = useNavigate();
  const { onboardingConfig, onOnboardingConfigChange } = UserInfo();

  const defaultOnboardedAppsData = {
    applications: [],
    status: Status.InProgress,
    success: '',
    error: ''
  };
  const [onboardedAppsData, setOnboardedAppsData] = useState(defaultOnboardedAppsData);
  const [selection, setSelection] = useState({
    selectedOnboardedAppId: '',
    activeOnboardedApp: {}
  });
  const [actionButtons, setActionButtons] = useState({
    editDisabled: true,
    removeDisabled: true
  });

  useEffect(() => {
    userProfileService
      .getOnboardedAppsForUser()
      .then((response) => {
        let formattedData = response.data.map((item) => {
          const [year, month, day, hour, minute, second, nanosecond] = item.createDateTime;
          const date = moment([year, month - 1, day, hour, minute, second, nanosecond / 1000000]);
          return {
            ...item,
            portfolioName: portfolios[item.portfolioShortName].name,
            createDateTime: date.format('D-MMM-YYYY h:mm:ss a')
          };
        });
        setOnboardedAppsData({
          ...onboardedAppsData,
          applications: formattedData,
          status: Status.Success
        });
        // Set the active application
        setSelection({ ...selection, activeOnboardedApp: onboardingConfig });
      })
      .catch((error) => {
        setOnboardedAppsData({
          ...onboardedAppsData,
          status: Status.Error,
          error:
            'Error while retrieving onboarded applications for your profile. Please refresh the page and try again.'
        });
        console.error('Error while retrieving onboarded applications for user: ', error);
      });
  }, []);

  const handleOnboardApplication = (event) => {
    navigate('/applications/new', { state: { redirectURI: '/applications' } });
  };

  const handleEditApplication = (event) => {
    navigate(`/applications/${selection.selectedOnboardedAppId}`, {
      state: { redirectURI: '/applications' }
    });
  };

  const handleRemoveApplication = (event) => {
    if (selection.selectedOnboardedAppId === selection.activeOnboardedApp.id) {
      setOnboardedAppsData({
        ...onboardedAppsData,
        status: Status.Error,
        error:
          'Cannot remove the active application. Please switch to another application before removing.'
      });
      return;
    }
    setOnboardedAppsData({ ...onboardedAppsData, status: Status.InProgress });
    userProfileService
      .removeOnboardingConfigId(selection.selectedOnboardedAppId)
      .then((response) => {
        const { updatedApplicationsData, removedApplication } =
          onboardedAppsData.applications.reduce(
            (acc, application) => {
              if (application.id !== selection.selectedOnboardedAppId) {
                acc.updatedApplicationsData.push(application);
              } else {
                acc.removedApplication = application;
              }
              return acc;
            },
            { updatedApplicationsData: [], removedApplication: null }
          );
        setOnboardedAppsData({
          ...onboardedAppsData,
          status: Status.Success,
          success: `Successfully removed application '${removedApplication.applicationName}' from your profile.`,
          applications: updatedApplicationsData
        });
        resetSelection();
      })
      .catch((error) => {
        console.error('Error while removing onboardingConfigId from user profile: ', error);
        setOnboardedAppsData({
          ...onboardedAppsData,
          status: Status.Error,
          error: 'Error while removing application from your profile. Please try again.'
        });
      });
  };

  const handleSelectionChange = (selectedOnboardedAppId) => {
    setSelection({
      ...selection,
      selectedOnboardedAppId: selectedOnboardedAppId
    });
    setActionButtons({
      ...actionButtons,
      editDisabled: false,
      removeDisabled: false
    });
    setOnboardedAppsData({
      ...onboardedAppsData,
      success: ''
    });
  };

  const resetSelection = () => {
    setSelection({
      ...selection,
      selectedOnboardedAppId: ''
    });
    setActionButtons({
      ...actionButtons,
      editDisabled: true,
      removeDisabled: true
    });
  };

  const handleOnboardingConfigChange = (newActiveOnboardedApp) => {
    setSelection({ ...selection, activeOnboardedApp: newActiveOnboardedApp });
    applicationOnboardingService
      .switchOnboardingConfigId(newActiveOnboardedApp.id)
      .then((userProfileResponse) => {
        console.log('User profile updated successfully: ');
      })
      .catch((error) => {
        console.error('Error while updating user profile: ', error);
      });
    onOnboardingConfigChange(newActiveOnboardedApp);
    setOnboardedAppsData({
      ...onboardedAppsData,
      status: Status.Success,
      success: `Successfully changed the active application to '${newActiveOnboardedApp.applicationName}'. 
      You can work with another application by opening new browser tab or window.`
    });
  };

  const columns = [
    {
      id: 'radioButton',
      label: '',
      sortDisabled: true
    },
    {
      id: 'id',
      label: 'ID',
      sortDisabled: true
    },
    {
      id: 'macId',
      label: 'MAC ID',
      sortDisabled: true
    },
    {
      id: 'portfolioName',
      label: 'Portfolio',
      sortDisabled: true
    },
    {
      id: 'platformName',
      label: 'Platform',
      sortDisabled: true
    },
    {
      id: 'applicationName',
      label: 'Application'
    },
    {
      id: 'createDateTime',
      label: 'Onboarded On',
      sortDisabled: true
    },
    {
      id: 'switchButton',
      label: 'Switch Application',
      sortDisabled: true
    }
  ];

  return (
    <>
      <div style={{ width: 'fit-content' }}>
        <h5 className="app__page-title">My Applications</h5>

        {onboardedAppsData.status === Status.InProgress ? <Loading /> : null}

        {onboardedAppsData.status === Status.Success && onboardedAppsData.success ? (
          <ShowSuccessNotification message={onboardedAppsData.success} />
        ) : null}

        {onboardedAppsData.status === Status.Error ? (
          <ShowErrorNotification message={onboardedAppsData.error} />
        ) : null}

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <McButton
              label="Onboard Application"
              variant="outlined"
              click={(event) => handleOnboardApplication(event)}
              style={{ marginRight: '4px' }}
            />
            {/* <McButton
                  label="Edit Application"
                  variant="outlined"
                  disabled={actionButtons.editDisabled}
                  click={(event) => handleEditApplication(event)}
                  style={{ marginRight: '4px' }}
                /> */}
            <McButton
              label="Remove Application"
              appearance="error"
              variant="outlined"
              disabled={actionButtons.removeDisabled}
              click={(event) => handleRemoveApplication(event)}
            />
          </div>

          <div style={{ marginTop: '16px' }}>
            <McTable data={onboardedAppsData.applications} columns={columns} datakey="macId">
              {onboardedAppsData.applications.map((onboardedApp) => (
                <React.Fragment key={onboardedApp.macId}>
                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    slot={`${onboardedApp.macId}_radioButton`}>
                    <McRadio
                      name={`${onboardedApp.macId}_radio`}
                      value={onboardedApp.id}
                      hiddenlabel
                      checked={onboardedApp.id === selection.selectedOnboardedAppId ? true : false}
                      click={(event) => {
                        handleSelectionChange(event.target.value);
                      }}></McRadio>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    slot={`${onboardedApp.macId}_switchButton`}>
                    <McSwitch
                      name={`${onboardedApp.macId}_switch`}
                      value={onboardedApp}
                      checked={onboardedApp.id === selection.activeOnboardedApp.id ? true : false}
                      hiddenlabel
                      disabled={onboardedAppsData.applications.length === 1 ? true : false}
                      onClick={(event) =>
                        handleOnboardingConfigChange(event.target.value)
                      }></McSwitch>
                  </div>
                </React.Fragment>
              ))}
            </McTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default Applications;

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
