import { McButton } from '@maersk-global/mds-react-wrapper';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { McTypeahead } from '@maersk-global/mds-react-wrapper/components-core/mc-typeahead';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShowErrorNotification, ShowSuccessNotification } from '../common/notification.jsx';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import ApplicationOnboardingService from './ApplicationOnboardingService.js';

const config = require('../config/config.json');

const getPortfolios = () => {
  const portfolioList = config.platformNames;
  // create a portfolio map for quick lookup
  // add undefined portfolio to the map
  const portfolioMap = { [config.undefinedPortfolio.macPortfolioId]: config.undefinedPortfolio };
  // add MAC portfolio id to portfolio mapping
  portfolioList.reduce((map, portfolio) => {
    map[portfolio.macPortfolioId] = portfolio;
    return map;
  }, portfolioMap);
  // add portfolio short name to portfolio mapping
  portfolioMap[config.undefinedPortfolio.value] = config.undefinedPortfolio;
  portfolioList.reduce((map, portfolio) => {
    map[portfolio.value] = portfolio;
    return map;
  }, portfolioMap);

  return { portfolioList: portfolioList, portfolioMap: portfolioMap };
};

const ApplicationOnboarding = ({ onApplicationOnboard }) => {
  const { userProfile } = UserInfo();

  const [cache, setCache] = useState({
    applications: [],
    platforms: [],
    platformMap: {},
    portfolioList: [],
    portfolioMap: {},
    status: Status.Pending,
    error: ''
  });
  const defaultOnboardedData = {
    onboardingConfig: OnboardingConfig.defaultOnboardingConfig(),
    status: Status.Pending,
    error: ''
  };
  const [appOnboardedData, setAppOnboardedData] = useState(defaultOnboardedData);
  const defaultAppSearchOptions = {
    options: null,
    loading: false
  };
  const [appSearchOptions, setAppSearchOptions] = useState(defaultAppSearchOptions);

  const [platformAndPortfolioEditable, setPlatformAndPortfolioEditable] = useState(false);

  const redirectURI = useLocation().state?.redirectURI;
  const navigate = useNavigate();

  const applicationOnboardingService = new ApplicationOnboardingService();

  useEffect(() => {
    // get applications and platforms from iPACE
    applicationOnboardingService
      .getAllOnboardingConfigs()
      .then((response) => {
        // get platforms from MAC
        applicationOnboardingService
          .getMACPlatforms()
          .then((macResponse) => {
            let applications = response.data;

            let ipacePlatforms = applications.reduce((uniqueSet, curr) => {
              return uniqueSet.add(curr.platformName);
            }, new Set());
            let macPlatforms = macResponse.data.content.map((each) => each.platformName);
            let uniquePlatforms = new Set([...ipacePlatforms, ...macPlatforms]);
            let allPlatforms = [...uniquePlatforms].sort();

            let portfolios = getPortfolios();

            setCache({
              applications: applications,
              platforms: allPlatforms,
              platformMap: uniquePlatforms,
              portfolioList: portfolios.portfolioList,
              portfolioMap: portfolios.portfolioMap,
              status: Status.Success
            });
          })
          .catch((error) => {
            console.error('Error while getting platforms from MAC.', error);
            setCache({
              status: Status.Error,
              error:
                'Unexpected error while getting platforms from MAC. Please refresh this page and try again.'
            });
          });
      })
      .catch((error) => {
        console.error('Error while getting onboarded applications and platforms.', error);
        setCache({
          status: Status.Error,
          error: 'Unexpected error while getting applications. Please refresh this page and try.'
        });
      });
  }, []);

  const handleSubmit = (event) => {
    if (!OnboardingConfig.isValid(appOnboardedData.onboardingConfig)) {
      setAppOnboardedData({
        ...appOnboardedData,
        status: Status.Error,
        error: 'Please check if application details are correct and try again.'
      });
      return;
    }
    setAppOnboardedData({ ...appOnboardedData, status: Status.InProgress });

    let onboardingConfigRequest;
    if (appOnboardedData.onboardingConfig.source === OnboardingConfig.IPACE) {
      onboardingConfigRequest = {
        id: appOnboardedData.onboardingConfig.id
      };
    } else if (appOnboardedData.onboardingConfig.source === OnboardingConfig.MAC) {
      onboardingConfigRequest = {
        macId: appOnboardedData.onboardingConfig.macId,
        applicationName: appOnboardedData.onboardingConfig.applicationName,
        platformName: appOnboardedData.onboardingConfig.platformName,
        portfolioShortName: appOnboardedData.onboardingConfig.portfolioShortName
      };
    } else {
      // user created application
      onboardingConfigRequest = {
        applicationName: appOnboardedData.onboardingConfig.applicationName,
        platformName: appOnboardedData.onboardingConfig.platformName,
        portfolioShortName: appOnboardedData.onboardingConfig.portfolioShortName
      };
    }

    console.info('Onboarding config request: ', onboardingConfigRequest);

    // create onboarding config for application
    applicationOnboardingService
      .createOnboardingConfig(onboardingConfigRequest)
      .then((onboardingConfigResponse) => {
        let onboardingConfig = onboardingConfigResponse.data;
        // update user profile with onboarded application
        applicationOnboardingService
          .addOnboardingConfigId(onboardingConfig.id)
          .then((userProfileResponse) => {
            if (onApplicationOnboard) {
              onApplicationOnboard(userProfileResponse.data, onboardingConfig);
              setAppOnboardedData({ ...appOnboardedData, status: Status.Success });
            } else if (redirectURI) navigate(redirectURI);
          })
          .catch((error) => {
            console.error('Error while updating user profile: ', error);
            setAppOnboardedData({
              ...appOnboardedData,
              status: Status.Error,
              error: 'Unexpected error. Please try onboarding again.'
            });
          });
      })
      .catch((error) => {
        console.error('Error while creating application onboarding config: ', error);
        setAppOnboardedData({
          ...appOnboardedData,
          status: Status.Error,
          error: 'Unexpected error. Please try onboarding again.'
        });
      });
  };

  const getApplicationInputPlaceholder = () => {
    if (cache.status === Status.Pending) return 'Loading applications and platforms...';
    return 'Start typing MAC id or application name, 3 characters minimum';
  };

  const resetState = () => {
    setAppOnboardedData(defaultOnboardedData);
    setAppSearchOptions(defaultAppSearchOptions);
    setPlatformAndPortfolioEditable(false);
  };

  const getAppTypeaheadOptions = (searchQuery, matchingApps, source) => {
    let searchOptions = [];
    // create new application option if user has not found any matching applications
    if (source === OnboardingConfig.USER) {
      let appOnboardingConfig = new OnboardingConfig(
        searchQuery,
        null,
        searchQuery,
        '',
        '',
        source
      );
      searchOptions.push({
        id: appOnboardingConfig.id,
        label: appOnboardingConfig.applicationName,
        sublabel: 'Create new application',
        value: appOnboardingConfig.id,
        appOnboardingConfig: appOnboardingConfig
      });
      return searchOptions;
    }
    // create options for matching applications
    matchingApps.forEach((matchingApp) => {
      let appOnboardingConfig = new OnboardingConfig(
        source === OnboardingConfig.IPACE ? matchingApp.id : matchingApp.applicationId,
        source === OnboardingConfig.IPACE ? matchingApp.macId : matchingApp.applicationId,
        matchingApp.applicationName,
        source === OnboardingConfig.IPACE
          ? matchingApp.platformName
          : cache.platformMap.has(matchingApp.platform.platformName)
          ? matchingApp.platform.platformName
          : '',
        source === OnboardingConfig.IPACE
          ? matchingApp.portfolioShortName
          : cache.portfolioMap[matchingApp.platform.portfolio.portfolioId]?.value,
        source
      );
      let searchOption = {
        id: appOnboardingConfig.id,
        label: appOnboardingConfig.applicationName,
        sublabel: `${appOnboardingConfig.macId} - ${appOnboardingConfig.platformName} - ${
          cache.portfolioMap[appOnboardingConfig.portfolioShortName]?.name
        }`,
        value: appOnboardingConfig.id + '', // value needs to be unique and string
        appOnboardingConfig: appOnboardingConfig
      };
      searchOptions.push(searchOption);
    });
    return searchOptions;
  };

  const onAppInput = (event) => {
    if (event.target.value || event.target.value.length < 3) {
      resetState();
      setAppOnboardedData({
        ...defaultOnboardedData,
        onboardingConfig: {
          ...defaultOnboardedData.onboardingConfig,
          applicationName: event.target.value
        }
      });
    }
  };

  const onAppSearch = (event) => {
    let searchQuery = event.detail;
    let searchOptions = [];
    // reset state
    resetState();
    setAppOnboardedData({
      ...defaultOnboardedData,
      onboardingConfig: { ...defaultOnboardedData.onboardingConfig, applicationName: searchQuery }
    });

    // search in MAC
    let matchingApps = [];
    setAppSearchOptions({ ...defaultAppSearchOptions, loading: true });
    applicationOnboardingService
      .getMACApplications(searchQuery)
      .then((response) => {
        matchingApps = response.data.content ? response.data.content : [];
        if (matchingApps.length > 0) {
          // matching apps found in MAC
          searchOptions = getAppTypeaheadOptions(searchQuery, matchingApps, OnboardingConfig.MAC);
        } else {
          // no matching apps found in MAC, search in iPACE
          matchingApps = cache.applications.filter(
            (application) =>
              application.macId.toLowerCase().includes(searchQuery.toLowerCase()) ||
              application.applicationName.toLowerCase().includes(searchQuery.toLowerCase())
          );
          if (matchingApps.length > 0) {
            // matching apps found in iPACE
            searchOptions = getAppTypeaheadOptions(
              searchQuery,
              matchingApps,
              OnboardingConfig.IPACE
            );
          } else {
            // no matching apps found in MAC and iPACE, let user specify new application
            searchOptions = getAppTypeaheadOptions(searchQuery, [], OnboardingConfig.USER);
          }
        }
        setAppSearchOptions({
          ...defaultAppSearchOptions,
          options: searchOptions,
          loading: false
        });
      })
      .catch((error) => {
        console.error('Error while getting applications from MAC.', error);
        setAppSearchOptions({ ...defaultAppSearchOptions, loading: false });
        setAppOnboardedData({
          ...defaultOnboardedData,
          status: Status.Error,
          error: 'Unexpected error while searching applications. Please try again.'
        });
      });
  };

  const onAppSelected = (event) => {
    let onboardingConfig = event.detail.appOnboardingConfig;
    setAppOnboardedData({
      ...appOnboardedData,
      onboardingConfig: onboardingConfig
    });
    if (
      onboardingConfig.platformName === 'Platform-Undefined' ||
      onboardingConfig.portfolioShortName === 'Portfolio-Undefined'
    ) {
      setPlatformAndPortfolioEditable(true);
    }
    setAppSearchOptions(defaultAppSearchOptions);
  };

  const onPlatformSelected = (event) => {
    setAppOnboardedData({
      ...appOnboardedData,
      onboardingConfig: {
        ...appOnboardedData.onboardingConfig,
        platformName: event.detail.value
      }
    });
  };

  const onPortfolioSelected = (event) => {
    setAppOnboardedData({
      ...appOnboardedData,
      onboardingConfig: {
        ...appOnboardedData.onboardingConfig,
        portfolioShortName: event.detail.value
      }
    });
  };

  return (
    <div>
      <h5 className="app__page-title">My Applications &gt; Onboard Application</h5>

      {cache.status === Status.Error ? <ShowErrorNotification message={cache.error} /> : null}
      {appOnboardedData.status === Status.Success ? (
        <ShowSuccessNotification message="Onboarded successfully." />
      ) : null}
      {appOnboardedData.status === Status.Error ? (
        <ShowErrorNotification message={appOnboardedData.error} />
      ) : null}
      <form onSubmit={(event) => handleSubmit(event)}>
        <div>
          <div className="field">
            <div className="field"></div>{' '}
            <McTypeahead
              name="applicationName"
              label="Application"
              value={appOnboardedData.onboardingConfig.applicationName}
              disabled={cache.status === Status.Pending || cache.status === Status.Error}
              placeholder={getApplicationInputPlaceholder()}
              required={true}
              loading={appSearchOptions.loading || cache.status === Status.Pending}
              minchars="3"
              optionsheight="265px"
              data={appSearchOptions.options}
              disablefilter={true}
              search={(event) => onAppSearch(event)}
              input={(event) => onAppInput(event)}
              optionselected={(event) => onAppSelected(event)}></McTypeahead>
            <div className="field"></div>{' '}
            <div>
              <div
                style={{
                  width: '100%',
                  display: 'flex'
                }}>
                <div style={{ width: '100%' }}>
                  <McSelect
                    name="platformName"
                    label="Platform"
                    value={
                      appOnboardedData.onboardingConfig.platformName === 'Platform-Undefined'
                        ? ''
                        : appOnboardedData.onboardingConfig.platformName
                    }
                    hint={
                      appOnboardedData.onboardingConfig.platformName === 'Platform-Undefined'
                        ? 'Platform is not defined for this application. Please select a platform.'
                        : ''
                    }
                    required={true}
                    disabled={!platformAndPortfolioEditable}
                    optionselected={(event) => {
                      onPlatformSelected(event);
                    }}>
                    {cache.platforms.map((platform, index) => (
                      <McOption key={index} value={platform}>
                        {platform}
                      </McOption>
                    ))}
                  </McSelect>
                </div>
              </div>
            </div>
            <div className="field"></div>{' '}
            <McSelect
              name="portfolioName"
              label="Portfolio"
              value={
                appOnboardedData.onboardingConfig.portfolioShortName === 'Portfolio-Undefined'
                  ? ''
                  : appOnboardedData.onboardingConfig.portfolioShortName
              }
              hint={
                appOnboardedData.onboardingConfig.portfolioShortName === 'Portfolio-Undefined'
                  ? 'Portfolio is not defined for this application. Please select a portfolio.'
                  : ''
              }
              required={true}
              disabled={!platformAndPortfolioEditable}
              optionselected={(event) => {
                onPortfolioSelected(event);
              }}>
              {cache.portfolioList.map((portfolio, index) => (
                <McOption key={index} value={portfolio.value}>
                  {portfolio.name}
                </McOption>
              ))}
            </McSelect>
            <div className="field"></div>{' '}
            <div className="layout layout__row button-group" style={{ paddingTop: '3%' }}>
              <McButton
                type="submit"
                loading={appOnboardedData.status === Status.InProgress}
                disabled={!OnboardingConfig.isValid(appOnboardedData.onboardingConfig)}>
                Onboard Application
              </McButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ApplicationOnboarding;

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};

class OnboardingConfig {
  static IPACE = 'ipace';
  static MAC = 'mac';
  static USER = 'user';

  constructor(id, macId, applicationName, platformName, portfolioShortName, source) {
    this.id = id;
    this.macId = macId;
    this.applicationName = applicationName;
    this.platformName = platformName ? platformName : 'Platform-Undefined';
    this.portfolioShortName = portfolioShortName ? portfolioShortName : 'Portfolio-Undefined';
    this.source = source;
  }

  static isValid(onboardingConfig) {
    return (
      onboardingConfig &&
      onboardingConfig.id &&
      onboardingConfig.applicationName &&
      onboardingConfig.platformName &&
      onboardingConfig.platformName !== 'Platform-Undefined' &&
      onboardingConfig.portfolioShortName &&
      onboardingConfig.portfolioShortName !== 'Portfolio-Undefined' &&
      onboardingConfig.source
    );
  }
  static defaultOnboardingConfig() {
    return new OnboardingConfig('default', '', '', 'default', 'default', '');
  }
}
